
import { postReqOptBuilder } from "./main_utils";


const CHUNK_SIZE = 5 * 1024 * 1024; // 5 MB chunks (adjust as needed)


const getFileExtFromBlob = (theBlob) => {
    const blobType = theBlob.type;
    var fileExt = "";
    if (blobType.includes("video/webm")) {
        fileExt += ".webm";
    } else if (blobType.includes("video/mp4")) {
        fileExt += ".mp4";
    } else if (blobType.includes("video/x-matroska")) {
        fileExt += ".mkv";
    } else {
        fileExt += "." + blobType.split("video/")[1].split(";")[0];
    }
    return fileExt;
}


const blobToFile = (theBlob, fileName) => {
    var finalFileName = fileName + getFileExtFromBlob(theBlob);
    return new File([theBlob], finalFileName, {type: theBlob.type.split(";")[0]});
}


const fetchPresignedUrls = async (para_be, file, file_id, retryCount=0) => {
    const data = {
        total_parts: Math.ceil(file.size / CHUNK_SIZE),
        id: file_id,
        filename: file.name,
        filetype: file.type,
    };
    const onFail = () => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const d = fetchPresignedUrls(para_be, file, file_id, retryCount + 1);
                resolve(d);
            }, 5000 + (retryCount * 5000));
        });
    }

    try {
        const resp = await fetch(`${para_be}/media/generate_presigned_url`, postReqOptBuilder(data));
        const jsonResponse = await resp.json();
        console.log("Presigned URL response:", jsonResponse);
        if (jsonResponse.status === 200) {
            return {
                upload_id: jsonResponse.result.upload_id,
                presigned_urls: jsonResponse.result.presign_urls,
            };
        } else {
            console.log("Failed generating presigned url");
            if (retryCount < 5) {
                return await onFail();
            } else return null;
        }
    } catch (error) {
        console.error("An error occurred:", error);
        if (retryCount < 5) {
            return await onFail();
        } else return null;
    }
};


const uploadFileChunk = async (presignedUrl, file, blob, uploadedChunks, totalChunks, updatePercentage, parts, i, onRetryUpload, retryCount=0) => {
    if (retryCount > 0 && retryCount < 5) {
        if (retryCount === 1) onRetryUpload();
        console.log("Checking if there is a network connection");
        const testResp = await fetch("https://aiv2.paraspot.ai/", {credentials: "include"});
        if (testResp.status !== 200) {
            setTimeout(() => {
                uploadFileChunk(presignedUrl, file, blob, uploadedChunks, totalChunks, updatePercentage, parts, i, onRetryUpload, retryCount + 1);
            }, 10000 + (retryCount * 5000));
        }
    } else if (retryCount === 5) {
        console.log("Failed to upload chunk after 5 retries");
        return;
    }
    return fetch(presignedUrl, {
        method: "PUT",
        headers: {"content-type": file.type},
        body: blob,
    })
    .then((response) => {
        if (response.ok) {
            uploadedChunks++;
            const progressPercentage = Math.floor(uploadedChunks / totalChunks * 100);
            updatePercentage(progressPercentage);
    
            const etag = response.headers.get("etag");
            parts.push({
                ETag: etag,
                PartNumber: i + 1,
            });
        } else {
            setTimeout(() => {
                uploadFileChunk(presignedUrl, file, blob, uploadedChunks, totalChunks, updatePercentage, parts, i, onRetryUpload, retryCount + 1);
            }, 10000 + (retryCount * 5000));
        }
    })
    .catch((error) => {
        // Handle error for this chunk upload here
        console.error("Chunk upload failed:", error);
        setTimeout(() => {
            uploadFileChunk(presignedUrl, file, blob, uploadedChunks, totalChunks, updatePercentage, parts, i, onRetryUpload, retryCount + 1);
        }, 10000 + (retryCount * 5000));
    });
}


const uploadFile = (para_be, file, upload_id, presigned_urls, file_id, updatePercentage, setLoader, setNotifState, onUploadComplete, onUploadFail, onRetryUpload) => {
    const parts = [];
    const totalChunks = presigned_urls.length;
    let uploadedChunks = 0;

    // Upload each chunk sequentially
    const chunkPromises = presigned_urls.map(async (presignedUrl, i) => {
        const blob = file.slice(i * CHUNK_SIZE, (i + 1) * CHUNK_SIZE);
        // Upload a single chunk to the presigned URL
        return await uploadFileChunk(presignedUrl, file, blob, uploadedChunks, totalChunks, updatePercentage, parts, i, onRetryUpload);
    });

    // Wait for all chunk uploads to complete
    Promise.all(chunkPromises)
    .then(() => {
        setLoader(true);
        // After all chunks are uploaded, send a request to finalize the upload
        fetch(`${para_be}/media/upload_video`, postReqOptBuilder({uploadId: upload_id, parts: parts, id: file_id, filename: file.name}))
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                try {
                    fetch(`${para_be}/scan/scanStarted`, postReqOptBuilder(
                        file_id.includes('_') ?
                            {pid: file_id.split("_")[1], scanType: 'checkout'} :
                            {pid: file_id, scanType: 'baseline'}
                    ))
                } catch (error) {
                    console.error("An error occurred while sending scanStarted notification:", error);
                }
                if (!file_id.includes('_')) {
                    try {
                        fetch(`${para_be}/scan/building_baseline`, postReqOptBuilder(
                                {uid: '', cid: '', pid: file_id}, 
                                true, {Authorization: document.cookie.split("AuthToken=")[1].split(";")[0]}
                        ))
                        .then(response2 => response2.json())
                        .then(response2 => {
                            if (setNotifState) {
                                if (response2.status === 200) {
                                    setNotifState({"type": "success", "msg": "Updated unit status"});
                                } else {
                                    setNotifState({"type": "error", "msg": "Failed to update unit status"});
                                }
                            }
                        })
                        .catch((x) => {
                            if (setNotifState) setNotifState({"type": "error", "msg": "Failed to update unit status"});
                        });
                    } catch (error) {
                        console.error("An error occurred while updating baseline status:", error);
                    }
                }
                
                setLoader(false);
                if (onUploadComplete) onUploadComplete();
            } else {
                setLoader(false);
                onUploadFail();
                console.error("Failed to upload video");
            }
        })
        .catch((error) => {
            // Handle any errors that occurred during the final steps
            setLoader(false);
            console.error("An error occurred:", error);
            onUploadFail();
        });
    })
    .catch((error) => {
        // Handle any errors that occurred during the final steps
        setLoader(false);
        console.error("An error occurred:", error);
        onUploadFail();
    });
};


export { blobToFile, fetchPresignedUrls, uploadFile, getFileExtFromBlob };