"use strict";

import React from "react";
import { isIOS, postReqOptBuilder } from "../../../utils/main_utils";

//style
import "./videoCamera.css";
//components
import MainLoader from "../../loaders/main_loader/main_loader";
import SimpleLoader from "../loader/simple-loader";
//containers
//assets
//constants
export const CAM_TYPE_BASELINE = 0;
export const CAM_TYPE_TENANT_SCAN = 1;
export const CAM_TYPE_DEMO = 2;
export const CAM_TYPE_TENANT_SCAN_NO_BASE = 3;

export const CAM_STATE_PAUSE = 0;
export const CAM_STATE_PLAY = 1;
export const MAX_WIDTH = 1280;
export const MAX_HEIGHT = 1280;
export const MIME_TYPE = "image/jpeg";
export const QUALITY = 0.8;

export default class VideoCamera extends React.Component {

    constructor(props) {
        super(props);
        // state based variables
        this.state = {
            camState: CAM_STATE_PAUSE,
            streamStarted: false,
            currentTS: '00:00',
            initPercent: 0,
        };

        // reference based variables
        this.scanner = React.createRef();
        this.canvas = React.createRef();
        this.mediaRecorderRef = React.createRef();
        this.vidChunks = React.createRef();
        this.vidChunks.current = [];
        // general variables
        this.sockIdx = 0;
        this.sessionID = null;
        this.context = null;
        this.intervalTransmitter = null;
        this.frame_index = -1;
        this.intervalCount = 0;
        this.corrupted_blobs = 0;
        this.canvasSizeSet = false;
        this.startingTS = new Date().getTime();
        // bind functions to scope
        this.intervalStreamer = this.intervalStreamer.bind(this);
        this.streamFrames = this.streamFrames.bind(this);
        this.changeScanState = this.changeScanState.bind(this);
        this.blobHandler = this.blobHandler.bind(this);
        this.finalizeStream = this.finalizeStream.bind(this);
        this.emitToSocket = this.emitToSocket.bind(this);
        this.setupVideoRecorder = this.setupVideoRecorder.bind(this);
    }

    test_vars(from) {
        console.log("-------------------------------------------------------------");
        console.log(`[${from}] **Test Vars:**`);
        console.log("scanner:", this.scanner.current);
        console.log("canvas:", this.canvas.current);
        console.log("sessionID:", this.sessionID);
        if (this.canvas.current) console.log("independent context:", this.canvas.current.getContext('2d'));
        console.log("context:", this.context);
        console.log("intervalTransmitter:", this.intervalStreamer);
        console.log("frame_index:", this.frame_index);
        console.log("intervalCount:", this.intervalCount);
        console.log("canvasSizeSet:", this.canvasSizeSet);
        console.log("-------------------------------------------------------------");
    }

    componentDidMount() {
        if (this.scanner.current) this.scanner.current.focus();
        if (this.sessionID === null) {
            // init scan session
            console.log("Session ID is null.\nInitializing...");
            this.initializeScanSession();
        }
        if (this.props.camType !== CAM_TYPE_TENANT_SCAN) this.openCam(this.props.cameraConstraints);
    }

    componentWillUnmount() {
        if (this.intervalTransmitter) clearInterval(this.intervalTransmitter);
    }

    initializeScanSession() {
        this.sessionID = this.props.sessionID ? this.props.sessionID : new Date().getTime().toString() + Math.random().toString(36).substr(2);
        console.log("My new Session ID:", this.sessionID);
        this.emitToSocket('init_scan', this.sessionID, this.props.camType === CAM_TYPE_DEMO, this.props.camType === CAM_TYPE_BASELINE, this.props.endUserInfo ? this.props.endUserInfo : "");
        fetch(this.props.para_be + "/scan/startingScan", postReqOptBuilder({"sid": this.sessionID, "end_user": this.props.endUserInfo ? this.props.endUserInfo : {}}));
        // this.props.socket.emit('init_scan', this.sessionID, this.props.camType === CAM_TYPE_DEMO, this.props.camType === CAM_TYPE_BASELINE, this.props.endUserInfo ? this.props.endUserInfo : "");
        if (this.props.transmitSid) this.props.transmitSid(this.sessionID);
    };
    // navigator.mediaDevices.getUserMedia({video: {deviceId: <device>, advanced: [{'aspectRatio: 1}]}}).then( (stream) => { let vid = document.getElementById("nathan-sohn"); vid.srcObject = stream; vid.play(); } )
    // navigator.mediaDevices.getUserMedia({video: {facingMode: {exact: "environment"}, advanced: [{'aspectRatio: 1}]}, audio: false}).then( (stream) => { let vid = document.getElementById("nathan-sohn"); vid.srcObject = stream; vid.play(); } )
    // navigator.mediaDevices.getUserMedia({video: true, audio: true}).then( (stream) => { let vid = document.getElementById("nathan-sohn"); vid.srcObject = stream; vid.play(); } )

    setupVideoRecorder(stream) {
        let medRecOpt = {mimeType: 'video/webm;codecs=vp9', bitsPerSecond: 10000000};
        try {
            this.mediaRecorderRef.current = new MediaRecorder(stream, medRecOpt);
        } catch (e1) {
            console.log('Unable to create MediaRecorder with options: ', medRecOpt, e1);
            try {
                medRecOpt = {mimeType: 'video/webm;codecs=vp8', bitsPerSecond: 10000000};
                this.mediaRecorderRef.current = new MediaRecorder(stream, medRecOpt);
            } catch (e2) {
                console.log('Unable to create MediaRecorder with options: ', medRecOpt, e2);
                try {
                    medRecOpt = {mimeType: 'video/x-matroska;codecs=avc1', bitsPerSecond: 2500000};
                    this.mediaRecorderRef.current = new MediaRecorder(stream, medRecOpt);
                } catch (e3) {
                    console.log('Unable to create MediaRecorder with options: ', medRecOpt, e3);
                    try {
                        medRecOpt = {mimeType: 'video/mp4', bitsPerSecond: 2500000};
                        this.mediaRecorderRef.current = new MediaRecorder(stream, medRecOpt);
                    } catch (e4) {
                        try {
                            this.mediaRecorderRef.current = new MediaRecorder(stream);
                        } catch (e5) {
                            console.log('Unable to create MediaRecorder', e5);
                        }
                    }
                }
            }
        }
        console.log("mediaRecorder:", this.mediaRecorderRef.current);
        this.mediaRecorderRef.current.ondataavailable = (e) => {
            console.log("At onDataAvailable mediaRecorder:", e);
            console.log("Pushing video chunk");
            this.vidChunks.current.push(e.data);
        };
        this.mediaRecorderRef.current.onerror = (e) => {
            console.log("[!] MediaRecorder Error:", e.error);
        };
        this.mediaRecorderRef.current.onstop = (e) => {
            console.log("At onStop mediaRecorder:", e);
            console.log("Creating blob out of video chunks. Size:", this.vidChunks.current.length);
            console.log("Creating blob out of video chunks:", this.vidChunks.current);
            var recordedBlob = new Blob(this.vidChunks.current, { type: e.srcElement.mimeType });
            console.log("recordedBlob:", recordedBlob);
            if (recordedBlob.size === 0 && this.vidChunks.current.length > 0 && this.vidChunks.current[0].size > 0) {
                console.log("Recorded blob size is 0 while chunks of blob where larger. Retrying...");
                recordedBlob = this.vidChunks.current[0];
            }
            // const recordedVidUrl = URL.createObjectURL(recordedBlob);
            // this.setState({recordedBlob: recordedBlob});
            this.props.setRecordedBlob(recordedBlob);
        };
    }

    async openCam(constraints) {
        this.test_vars("openCam");
        console.log("Opening camera with the following constraints:", constraints);
        navigator.mediaDevices.getUserMedia(constraints)
            .then((stream) => {
                this.setupVideoRecorder(stream);
                try {
                    this.scanner.current.srcObject = stream;
                } catch (e) {
                    console.log("[!]  Failed to load stream to srcObject");
                    console.log(e);
                    if ('srcObject' in this.scanner.current && e.name !== "TypeError") {
                        throw e;
                    }
                    if (window.webkitURL) {
                        console.log("[i] Running webkitURL for stream to the video element");
                        this.scanner.current.src = window.webkitURL.createObjectURL(stream);
                    } else {
                        console.log("[i] Running URL for stream to the video element");
                        this.scanner.current.src = URL.createObjectURL(stream);
                    }
                }
                console.log("[i] Attached stream to video");
                this.scanner.current.play();
                console.log("[i] Playing video");
            })
            .catch((err) => {
                console.log("Something went wrong while opening camera");
                console.log(err);
                this.scanner.current.pause();
                this.props.showError(
                    <>
                        We failed accessing your camera.<br/>
                        This error means you need to add camera permissions to your browser.<br/>
                        If you are using IOS go to Settings, click on your browser app, and then click on Camera. Set it to Ask/Allow.<br/>
                        If you are using Android go to Settings, click on Applications, click on your browser app, click on Permissions, and then select the Camera. Set it to Ask/Allow.
                    </>
                );
            });
    };

    async startStream(constraints) {
        this.test_vars("startStream");
        console.log("Starting stream with the following constraints:", constraints);
        navigator.mediaDevices.getUserMedia(constraints)
            .then((stream) => {
                this.setupVideoRecorder(stream);
                try {
                    this.scanner.current.srcObject = stream;
                } catch (e) {
                    console.log("[!] Failed to load stream to srcObject");
                    console.log(e);
                    if ('srcObject' in this.scanner.current && e.name !== "TypeError") {
                        throw e;
                    }
                    if (window.webkitURL) {
                        console.log("[i] Running webkitURL for stream to the video element");
                        this.scanner.current.src = window.webkitURL.createObjectURL(stream);
                    } else {
                        console.log("[i] Running URL for stream to the video element");
                        this.scanner.current.src = URL.createObjectURL(stream);
                    }
                }
                console.log("[i] Attached stream to video");
                this.scanner.current.play();
                this.startingTS = new Date().getTime();
                console.log("[i] Playing video");
                this.setState({streamStarted: true});
                if (this.props.scanLimit) {
                    setTimeout(() => {
                        if (this.state.camState === CAM_STATE_PLAY) this.changeScanState();
                    }, this.props.scanLimit*1000);
                }
                if (this.props.showTimeStamp) {
                    this.timeInterval = setInterval(() => {
                        let _tmpTS = new Date().getTime();
                        let d = (_tmpTS - this.startingTS) / 1000;
                        let m = parseInt(d / 60);
                        let s = parseInt(d % 60);
                        this.setState({currentTS: `${m >= 10 ? m : ("0" + m)}:${s >= 10 ? s : ("0" + s)}`})
                    }, 1000)
                }
            })
            .catch((err) => {
                console.log("Something went wrong while loading stream");
                console.log(err);
                this.scanner.current.pause();
                this.props.showError(
                    <>
                        We failed accessing your camera.<br/>
                        This error means you need to add camera permissions to your browser.<br/>
                        If you are using IOS go to Settings, click on your browser app, and then click on Camera. Set it to Ask/Allow.<br/>
                        If you are using Android go to Settings, click on Applications, click on your browser app, click on Permissions, and then select the Camera. Set it to Ask/Allow.
                    </>
                );
            });
    };

    changeScanState() {
        this.test_vars("changeScanState");
        if (this.state.camState === CAM_STATE_PLAY) {
            // console.log("[!DEBUG] Stopping mediaRecorderRef....\n", this.mediaRecorderRef.current);
            this.mediaRecorderRef.current.stop();
            this.scanner.current.pause();
            clearInterval(this.intervalTransmitter);
            if (this.props.showTimeStamp) clearInterval(this.timeInterval);
            console.log("Switching to loading");
            this.props.switchToLoading(this.sessionID, this.intervalCount, this.frame_index, this.corrupted_blobs);
            console.log("Starting finalization interval");
            this.finalizationInterval = setInterval(this.finalizeStream, 4000);
            console.log("Changing cam state");
            this.setState({camState: CAM_STATE_PAUSE});
        } else {
            console.log("Initializing scan...");
            this.setState({camState: CAM_STATE_PLAY});
            if (this.state.streamStarted) {
                this.initializeScanSession();
                this.scanner.current.play();
                this.scanner.current.focus();
            } else if ('mediaDevices' in navigator && navigator.mediaDevices.getUserMedia) {
                console.log(navigator.mediaDevices.getSupportedConstraints());
                if (this.props.camType === CAM_TYPE_TENANT_SCAN) {
                    this.startStream(this.props.cameraConstraints);
                } else {
                    this.startingTS = new Date().getTime();
                    this.setState({streamStarted: true});
                    this.streamFrames();
    
                    if (this.props.scanLimit) {
                        setTimeout(() => {
                            if (this.state.camState === CAM_STATE_PLAY) this.changeScanState();
                        }, this.props.scanLimit*1000);
                    }
                    if (this.props.showTimeStamp) {
                        this.timeInterval = setInterval(() => {
                            let _tmpTS = new Date().getTime();
                            let d = (_tmpTS - this.startingTS) / 1000;
                            let m = parseInt(d / 60);
                            let s = parseInt(d % 60);
                            this.setState({currentTS: `${m >= 10 ? m : ("0" + m)}:${s >= 10 ? s : ("0" + s)}`})
                        }, 1000)
                    }
                }
            }
        }
    };

    emitToSocket(ev, ...args) {
        if (this.props.sockets) {
            let localSockIdx = this.sockIdx;
            if (this.sockIdx+1 === this.props.sockets.length) {
                this.sockIdx = 0;
            } else {
                this.sockIdx++;
            }
            this.props.sockets[localSockIdx].emit(ev, ...args);
        } else this.props.socket.emit(ev, ...args);
    }

    finalizeStream() {
        console.log("Running finalize stream");
        console.log(`intervalCount=${this.intervalCount}  |  frame_index=${this.frame_index}  |  corrupted_blobs=${this.corrupted_blobs}`);
        this.props.switchToLoading(this.sessionID, this.intervalCount, this.frame_index, this.corrupted_blobs);
        if (this.intervalCount-1 === this.frame_index-this.corrupted_blobs) {
            console.log(`Finalizing scan for ${this.intervalCount} frames...`);
            if (this.props.finalizationCallback) {
                this.props.finalizationCallback(this.sessionID, this.intervalCount);
            } else {
                this.emitToSocket('finalize_scan', this.sessionID, this.intervalCount-1);
                // this.props.socket.emit('finalize_scan', this.sessionID, this.intervalCount-1);
            }
            clearInterval(this.finalizationInterval);
        }
    }

    calculateSize(vid) {
        let w = vid.videoWidth;
        let h = vid.videoHeight;
        console.log("Calculating size for video.\nInitial Size:\nWidth =>", w, "\nHeight =>", h)
        
        if (w > h && isIOS()) {
            return [0, 0]
        }
        let tmax = this.props.compressSize || MAX_WIDTH;
        if (w > h && w > tmax) {
            h = Math.round((h * tmax) / w);
            w = tmax;
        } else if (h > w && h > tmax) {
            w = Math.round((w * tmax) / h);
            h = tmax;
        }
        console.log("Final Size:\nWidth =>", w, "\nHeight =>", h)
        return [w, h]
    };

    readableBytes(bytes) {
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
    };

    blobHandler(blob, localIndex) {
        // console.log(`[i/F-${localIndex}] At toBlob`);
        if (blob) {
            // console.log(`[i/F-${localIndex}] Blob is not null`);
            // console.log(`[i/F-${localIndex}] Caching frame`);
            if (localIndex >= 0) this.props.framesCache.current[localIndex] = blob;
            // console.log(`[i/F-${localIndex}] framesCache keys:`, Object.keys(this.props.framesCache.current));
            // this.frame_index++;
            // console.log(`[i/F-${localIndex}/blob] File Size - ${this.readableBytes(blob.size)}`);
            // console.log(`[i/F-${localIndex}] Sending frame for processing...`);
            // try {
            this.emitToSocket('frame_processor', this.sessionID, blob, localIndex);
            // } catch (e) {
            //     console.log(e);
            //     console.log("[!blobHandler]", this.sessionID, "${", localIndex, "} =>", blob);
            // }
            // this.props.socket.emit('frame_processor', this.sessionID, blob, localIndex);
            // console.log(`[i/F-${localIndex}] Sent frame`);
            // this.props.socket.emit('frame_processor_test', this.sessionID, blob, this.frame_index);
        } else if (localIndex >= 0) {
            console.log(`[i/F-${localIndex}] Decreased from interval count due to no blob...`);
            this.intervalCount--;
            this.corrupted_blobs++;
            this.emitToSocket('stream_ignore', this.sessionID, localIndex)
            // this.props.socket.emit('stream_ignore', this.sessionID, localIndex)
        }
    }

    async intervalStreamer() {
        this.frame_index++;
        let localIndex = this.frame_index;
        // console.log(`[i/F-${localIndex}] Starting intervalTransmitter`);
        if (!this.canvasSizeSet) {
            // console.log(`[i/F-${localIndex}] Setting canvas size`);
            const [newWidth, newHeight] = this.calculateSize(this.scanner.current);
            // const newWidth = this.scanner.current.videoWidth, newHeight = this.scanner.current.videoHeight;
            this.canvas.current.width = newWidth;
            this.canvas.current.height = newHeight;
            if ((this.props.compressSize && newWidth === this.props.compressSize) || newWidth === MAX_WIDTH || newHeight === MAX_HEIGHT) this.canvasSizeSet = true;
        }
        if (!this.context || !(this.context instanceof CanvasRenderingContext2D)) {
            console.log("Context is null or is not instance of CanvasRenderingContext2D which basically means the MF changed...");
            this.context = this.canvas.current.getContext('2d');
        }
        // console.log(`[i/F-${localIndex}] Drawing Frame`);
        this.context.drawImage(this.scanner.current, 0, 0, this.canvas.current.width, this.canvas.current.height);
        // console.log(`[i/F-${localIndex}] Converting canvas to blob`);
        if (this.canvas.current.width !== 0 && this.canvas.current.height !== 0) {
            this.canvas.current.toBlob((blob) => this.blobHandler(blob, localIndex), MIME_TYPE, QUALITY);
            // console.log(`[i/F-${localIndex}] Adding to interval count`);
            this.intervalCount++;
        } else {
            // console.log(`[i/F-${localIndex}] Added to corrupted blobs due to blob corrupted size...`);
            this.corrupted_blobs++;
            this.emitToSocket('stream_ignore', this.sessionID, localIndex);
            // this.props.socket.emit('stream_ignore', this.sessionID, localIndex);
        }
        // console.log(`[i/F-${localIndex}] Clearing context`);
        this.context.clearRect(0, 0, this.canvas.current.width, this.canvas.current.height);
        // console.log(`[i/F-${localIndex}] Finished iteration`);
    }

    streamFrames() {
        if (!this.context || !(this.context instanceof CanvasRenderingContext2D)) this.context = this.canvas.current.getContext('2d');
        this.frame_index = -1;
        if (this.props.camType === CAM_TYPE_TENANT_SCAN) {
            let _this = this;
            setTimeout(() => {
                _this.intervalTransmitter = setInterval(_this.intervalStreamer, 1000/_this.props.FPS);
            }, isIOS() ? 1200 : 200);
        } else this.intervalTransmitter = setInterval(this.intervalStreamer, 1000/this.props.FPS);
        if (isIOS()) {
            this.mediaRecorderRef.current.start(1000);
        } else {
            this.mediaRecorderRef.current.start();
        }
    };

    renderChildren() {
        return "";
    }

    render() {
        const {camType} = this.props;
        return (
            <div className="display-cover" id="videoApp">
                {/* <MainLoader text="We are initializing the scan, it might take a couple of seconds"/> */}
                {camType === CAM_TYPE_TENANT_SCAN && this.state.camState !== CAM_STATE_PLAY ?
                    <SimpleLoader loadedPercentage={Math.round(this.state.initPercent)} msg={<>We are initializing the scan, it might take a few moments<br/>Do not leave the screen</>}/>
                    :
                    <>
                        {this.props.showTimeStamp && 
                            <div className={`rec-timestamp${this.state.camState === CAM_STATE_PLAY ? "" : " d-none"}`}>
                                <div className="ts-wrapper">
                                    <div id="ts-logger" className="text-3-2">{this.state.currentTS}</div>
                                </div>
                            </div>
                        }
                        <div className={`rec-identifier${this.state.camState === CAM_STATE_PLAY ? "" : " d-none"}`}>
                            <div></div>
                            <span>Rec</span>
                        </div>

                        {this.renderChildren()}

                        {camType === CAM_TYPE_TENANT_SCAN ?
                            <video ref={this.scanner} className="video-camera-display" onPlay={() => this.streamFrames()} autoPlay playsInline></video>
                            :
                            <video ref={this.scanner} className="video-camera-display" autoPlay playsInline></video>
                        }
                        <canvas ref={this.canvas} className="d-none"></canvas>

                        {camType === CAM_TYPE_BASELINE || camType === CAM_TYPE_DEMO || camType === CAM_TYPE_TENANT_SCAN_NO_BASE ?
                            <div className={`btn-container${this.state.camState === CAM_STATE_PLAY ? " btn-active" : ""}`}>
                                <div className="btn-outer" onClick={(e) => this.changeScanState()}>
                                    <div className="btn-inner"></div>
                                </div>
                            </div> : ""
                        }
                        {/*<div id="tempLog"></div>*/}
                    </>
                }
            </div>
        )
    }
}